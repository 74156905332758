import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Stage from '@components/stage'
import useStaticModule from '@hooks/useStaticModule'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  article: {
    ...theme.mixins.article,
    '& > h2': {
      fontSize: '1.17em',
    },
  },
}))

function Netiquette({ pageContext }) {
  const classes = useStyles()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue('netiquette.copy', 'netiquette.copy.text')
  const headline = getStaticFieldValue(
    'netiquette.stage',
    'netiquette.stage.headline'
  )

  return (
    <>
      <Stage alignTop={true} type={'generic'}
      title={headline}
      ariaLabel={headline}
       />

      <Container width="sm" ariaLabel="arialabel.netiquette">
        <Copy
          type="article"
          html={copy}
          component="div"
          className={classes.article}
        />
      </Container>
    </>
  )
}

Netiquette.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default Netiquette
